import React, { useRef, useState } from 'react';

const SignatureCanvas = ({ isFinishSignature, setIsFinishSignature }) => {
	const canvasRef = useRef(null);
	const [isDrawing, setIsDrawing] = useState(false);
	const [canvasWidth, setCanvasWidth] = useState(400);
	const [canvasHeight, setCanvasHeight] = useState(200);

	const startDrawing = () => {
		if (!isFinishSignature) {
			setIsDrawing(true);
			const canvas = canvasRef.current;
			const context = canvas.getContext('2d');
			context.beginPath();
		}
	};

	const endDrawing = () => {
		setIsDrawing(false);
	};

	const draw = (event) => {
		if (!isDrawing) return;

		// set the width and the hieght of the canvas
		setCanvasWidth(event.target.scrollWidth);
		setCanvasHeight(event.target.scrollHeight);

		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');
		const rect = canvas.getBoundingClientRect();

		const x = event.clientX
			? event.clientX - rect.left
			: event.changedTouches[0].clientX - rect.left;
		const y = event.clientY
			? event.clientY - rect.top
			: event.changedTouches[0].clientY - rect.top;

		context.lineTo(x, y);

		context.stroke();
	};

	const clearCanvas = () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		context.clearRect(0, 0, canvas.width, canvas.height);
		context.beginPath();
	};

	const hideButtonsAndBorder = (e) => {
		document.body.style.overflow = 'unset';
		const canvas = canvasRef.current;

		// check if the canvas is empty
		if (
			canvas
				.getContext('2d')
				.getImageData(0, 0, canvas.width, canvas.height)
				.data.some((channel) => channel !== 0)
		) {
			e.target.parentElement.style.display = 'none';
			canvas.parentElement.classList.add('finish');
			setIsFinishSignature(true);
			return;
		}

		alert('אנא מלא את החתימה שלך');
	};

	return (
		<div className='signature-canvas flex-column align-end'>
			<canvas
				ref={canvasRef}
				width={canvasWidth}
				height={canvasHeight}
				style={{ border: '1px solid #000' }}
				onMouseDown={startDrawing}
				onMouseUp={endDrawing}
				onMouseMove={(e) => {
					draw(e);
				}}
				// for mobile
				onTouchStart={startDrawing}
				onTouchEnd={endDrawing}
				onTouchMove={(e) => {
					draw(e);
				}}
			/>
			<div className='buttons flex justify-center'>
				<button onClick={clearCanvas}>ניקוי</button>
				<button onClick={hideButtonsAndBorder}>סיום</button>
			</div>
		</div>
	);
};

export default SignatureCanvas;
