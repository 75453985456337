import { axiosPost, SERVER_ENDPOINT } from './index';

export const SendDataAndFile = async (details, file, recruitingEmail) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/sendMail?email=${recruitingEmail}`,
		{
			details,
			file,
		},
	);
};
