import React from 'react';

function ThanksPopUp({ showPopUp }) {
	return (
		<div
			className={showPopUp ? 'popup-wrapper show' : 'popup-wrapper'}
			onClick={() => {
				document.location.reload();
			}}
		>
			<div className='popup'>
				<h2>תודה רבה !</h2>
				<h3>המשך יום נעים</h3>
			</div>
		</div>
	);
}

export default ThanksPopUp;
