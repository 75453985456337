import Axios from 'axios';

// export const SERVER_ENDPOINT = 'http://localhost:3009';
export const SERVER_ENDPOINT = 'https://surveyapp.portaltempo.co.il';

export const axiosPost = async (url, data, type = 'application/json') => {
	const res = await Axios.post(url, data, {
		withCredentials: true,
		mode: 'cors',
		headers: {
			'Content-Type': type,
			'Access-Control-Allow-Origin': SERVER_ENDPOINT,
		},
	});
	return res.data;
};
