import { useEffect, useRef, useState } from 'react';
import './assets/scss/general.scss';
import './assets/scss/form.scss';
import tempo0 from './assets/imgs/tempo0.png';
import tempoLogo from './assets/imgs/tempoLogo.png';
import tempoFooter1 from './assets/imgs/tempo-footer1.png';
import tempoFooter2 from './assets/imgs/tempo-footer2.png';
import tempoFooter3 from './assets/imgs/tempo-footer3.png';
import tempoFooter4 from './assets/imgs/tempo-footer4.png';
import SignatureCanvas from './components/SignatureCanvas';
import html2pdf from 'html2pdf.js';
import { SendDataAndFile } from './services/api';
import ThanksPopUp from './components/ThanksPopUp';
import Loader from './components/Loader';

function App() {
	const [personalDetails, setPersonalDetails] = useState({
		lastName: '',
		firstName: '',
		idNumber: '',
		gender: '',
		birthDate: '',
		birthCountry: '',
		yearOfImmigration: '',
		address: '',
		phoneNumber: '',
		profession: '',
		hasCar: '',
		haslicense: '',
	});
	const [educationDetails, setEducationDetails] = useState({
		highSchool: {
			years: '',
			mainSubject: '',
			nameAndCertificates: '',
		},
		university: {
			years: '',
			mainSubject: '',
			nameAndCertificates: '',
		},
	});
	const [coursesDetails, setCoursesDetails] = useState([
		{
			courseName: '',
			years: '',
			subject: '',
			institutionAndCertificates: '',
		},
	]);
	const [appliedToTempo, setAppliedToTempo] = useState({
		applied: '',
		role: '',
	});
	const [hasContactWithTempoMember, setHasContactWithTempoMember] = useState({
		hasContact: '',
		kindOfContact: '',
		withWho: '',
	});
	const [hasRelationWithTempoMember, setHasRelationWithTempoMember] =
		useState({
			hasRelation: '',
			kindOfRelation: '',
			whichRole: '',
		});
	const [previousWorkPlaces, setPreviousWorkPlaces] = useState([
		{
			workPlaceName: '',
			role: '',
			from: '',
			to: '',
			salary: '',
			reasonForLeaving: '',
		},
	]);
	const [recommendations, setRecommendations] = useState([
		{
			recommendName: '',
			phoneNumber: '',
			role: '',
			fromWhere: '',
		},
	]);
	const [languages, setLanguages] = useState([
		{
			language: '',
			speaking: '',
			reading: '',
			writing: '',
			typing: '',
		},
	]);
	const [computerApplications, setComputerApplications] = useState([
		{
			softwareName: '',
			controllingLevel: '',
		},
	]);
	const [deficiencies, setDeficiencies] = useState('');
	const [signatureDate, setsignatureDate] = useState(
		new Date().toISOString().split('T')[0],
	);
	const [comments, setComments] = useState('');
	const [isFinishSignature, setIsFinishSignature] = useState(false);

	const contentRef = useRef();
	const [isGeneratePdf, setIsGeneratePdf] = useState(false);
	const [showPopUp, setShowPopUp] = useState(false);
	const [recruitingEmail, setRecruitingEmail] = useState('');
	const [showLoader, setShowLoader] = useState(false);

	const recruitings = [
		{
			id: 1,
			email: 'kerenhu@tempo.co.il',
		},
		{
			id: 2,
			email: 'galz@tempo.co.il',
		},
		{
			id: 3,
			email: 'anatB@tempo.co.il',
		},
		{
			id: 4,
			email: 'estery@tempo.co.il',
		},
		{
			id: 5,
			email: 'tamiS@tempo.co.il',
		},
		{
			id: 6,
			email: 'nurit@tempo.co.il',
		},
		{
			id: 7,
			email: 'helir@tempo.co.il',
		},
		{
			id: 8,
			email: 'ivetteA@tempo.co.il',
		},
		{
			id: 9,
			email: 'michal@barkan-winery.co.il',
		},
	];

	// function for personal details
	const onInputChange = (key, value) => {
		const previousDetails = { ...personalDetails };
		if (value && value.length) {
			previousDetails[key] = value;
			setPersonalDetails(previousDetails);
			return;
		}
	};
	// function for education table
	const onEducationChange = (key, field, value) => {
		const previousDetails = { ...educationDetails };
		if (value && value.length) {
			previousDetails[key][field] = value;
			setEducationDetails(previousDetails);
		}
	};
	// function for courses table
	const onCoursesChange = (e, key, value) => {
		let arrayIndex = e.dataset.index - 1;
		const previousDetails = [...coursesDetails];
		if (previousDetails.length - 1 < arrayIndex) {
			previousDetails.push({
				courseName: '',
				years: '',
				subject: '',
				institutionAndCertificates: '',
			});
		}
		const tempDetails = { ...previousDetails[arrayIndex] };
		if (value && value.length) {
			tempDetails[key] = value;
			previousDetails[arrayIndex] = tempDetails;
			setCoursesDetails(previousDetails);
		}
	};
	// function for apllied to tempo
	const onAppliedChange = (key, value) => {
		const previousDetails = { ...appliedToTempo };
		if (value && value.length) {
			previousDetails[key] = value;
			setAppliedToTempo(previousDetails);
		}
	};
	// function for has contact
	const onHasContactChange = (key, value) => {
		const previousDetails = { ...hasContactWithTempoMember };
		if (value && value.length) {
			previousDetails[key] = value;
			setHasContactWithTempoMember(previousDetails);
		}
	};
	// function for has relation
	const onHasRelationChange = (key, value) => {
		const previousDetails = { ...hasRelationWithTempoMember };
		if (value && value.length) {
			previousDetails[key] = value;
			setHasRelationWithTempoMember(previousDetails);
		}
	};
	// function for previous work places table
	const onPreviousWorkPlacesChange = (e, key, value) => {
		let arrayIndex = e.dataset.index - 1;
		const previousDetails = [...previousWorkPlaces];
		if (previousDetails.length - 1 < arrayIndex) {
			previousDetails.push({
				workPlaceName: '',
				role: '',
				from: '',
				to: '',
				salary: '',
				reasonForLeaving: '',
			});
		}
		const tempDetails = { ...previousDetails[arrayIndex] };
		if (value && value.length) {
			tempDetails[key] = value;
			previousDetails[arrayIndex] = tempDetails;
			setPreviousWorkPlaces(previousDetails);
		}
	};
	// function for recommendations table
	const onRecommendationsChange = (e, key, value) => {
		let arrayIndex = e.dataset.index - 1;
		const previousDetails = [...recommendations];
		if (previousDetails.length - 1 < arrayIndex) {
			previousDetails.push({
				recommendName: '',
				phoneNumber: '',
				role: '',
				fromWhere: '',
			});
		}
		const tempDetails = { ...previousDetails[arrayIndex] };
		if (value && value.length) {
			tempDetails[key] = value;
			previousDetails[arrayIndex] = tempDetails;
			setRecommendations(previousDetails);
		}
	};
	// function for languages table
	const onLanguagesChange = (e, key, value) => {
		let arrayIndex = e.dataset.index - 1;
		const previousDetails = [...languages];
		if (previousDetails.length - 1 < arrayIndex) {
			previousDetails.push({
				language: '',
				speaking: '',
				reading: '',
				writing: '',
				typing: '',
			});
		}
		const tempDetails = { ...previousDetails[arrayIndex] };
		if (value && value.length) {
			tempDetails[key] = value;
			previousDetails[arrayIndex] = tempDetails;
			setLanguages(previousDetails);
		}
	};
	// function for computer applications table
	const onComputerApplicationsChange = (e, key, value) => {
		let arrayIndex = e.parentElement.parentElement.rowIndex - 1;
		const previousDetails = [...computerApplications];
		if (previousDetails.length - 1 < arrayIndex) {
			previousDetails.push({
				softwareName: '',
				controllingLevel: '',
			});
		}
		const tempDetails = { ...previousDetails[arrayIndex] };
		if (value && value.length) {
			tempDetails[key] = value;
			previousDetails[arrayIndex] = tempDetails;
			setComputerApplications(previousDetails);
		}
	};

	// send form function
	const GeneratePdf = async () => {
		// set the text that should be send as a mail
		const detailsText = validateDataAndCreateText();
		if (!detailsText) return;

		setIsGeneratePdf(true);

		// generate the pdf
		const element = contentRef.current;
		const opt = {
			filename: 'Register.pdf',
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', orientation: 'portrait' },
			pagebreak: {
				mode: 'avoid-all',
				before: '.deficiencies',
				after: '.courses',
			},
		};

		html2pdf()
			.set(opt)
			.from(element)
			.outputPdf()
			.then(function (pdf) {
				// This logs the right base64
				sendPdfByMail(btoa(pdf));
			});
	};

	// validate details and create one message text
	const validateDataAndCreateText = () => {
		for (let field in personalDetails) {
			if (field === 'lastName' && personalDetails[field].length < 2) {
				alert('אנא מלא שם משפחה');
				return;
			}
			if (field === 'firstName' && personalDetails[field].length < 2) {
				alert('אנא מלא שם פרטי');
				return;
			}
			if (field === 'idNumber' && personalDetails[field].length !== 9) {
				alert('אנא מלא תעודת זהות כראוי');
				return;
			}
			if (field === 'gender' && !personalDetails[field]) {
				alert('אנא מלא מין');
				return;
			}
			if (field === 'birthDate' && !personalDetails[field]) {
				alert('אנא מלא תאריך לידה');
				return;
			}
			if (field === 'birthCountry' && !personalDetails[field].length) {
				alert('אנא מלא ארץ לידה');
				return;
			}
			if (field === 'address' && !personalDetails[field].length) {
				alert('אנא מלא כתובת');
				return;
			}
			if (field === 'phoneNumber' && !personalDetails[field].length) {
				alert('אנא מלא תעודת מספר נייד');
				return;
			}
			if (field === 'hasCar' && !personalDetails[field]) {
				alert('אנא מלא אם יש ברשותך רכב');
				return;
			}
			if (field === 'haslicense' && !personalDetails[field]) {
				alert('אנא מלא אם יש ברשותך רישיון נהיגה');
				return;
			}
		}

		if (!appliedToTempo.applied) {
			alert('אנא מלא את סעיף 4');
			return;
		} else if (appliedToTempo.applied === 'yes') {
			if (!appliedToTempo.role) {
				alert('אנא מלא את סעיף 4 כראוי');
				return;
			}
		}

		if (!hasContactWithTempoMember.hasContact) {
			alert('אנא מלא את סעיף 5');
			return;
		} else if (hasContactWithTempoMember.hasContact === 'yes') {
			if (
				!hasContactWithTempoMember.kindOfContact ||
				!hasContactWithTempoMember.withWho
			) {
				alert('אנא מלא את סעיף 5 כראוי');
				return;
			}
		}

		if (!hasRelationWithTempoMember.hasRelation) {
			alert('אנא מלא את סעיף 6');
			return;
		} else if (hasRelationWithTempoMember.hasRelation === 'yes') {
			if (
				!hasRelationWithTempoMember.kindOfRelation ||
				!hasRelationWithTempoMember.whichRole
			) {
				alert('אנא מלא את סעיף 6 כראוי');
				return;
			}
		}

		// if (!signatureDate.date) {
		// 	alert('אנא מלא את תאריך החתימה');
		// 	return;
		// }

		if (!isFinishSignature) {
			alert('אנא מלא את החתימה');
			return;
		}

		let message = `
			פרטים אישיים:
			שם משפחה: ${personalDetails.lastName}
			שם פרטי: ${personalDetails.firstName}
			מס' זהות: ${personalDetails.idNumber}
			מין: ${personalDetails.gender === 'male' ? 'זכר' : 'נקבה'}
			תאריך לידה: ${personalDetails.birthDate}
			ארץ לידה: ${personalDetails.birthCountry}
			${
				personalDetails.yearOfImmigration.length
					? 'שנת עליה: ' + personalDetails.yearOfImmigration
					: ''
			}
			כתובת: ${personalDetails.address}
			מס' נייד: ${personalDetails.phoneNumber}
			${
				personalDetails.profession.length
					? 'מקצוע/עיסוק: ' + personalDetails.profession
					: ''
			}
			בעל/ת רכב: ${personalDetails.hasCar === 'has-car' ? 'כן' : 'לא'}
			ברשותי יש / אין רישיון נהיגה: ${
				personalDetails.haslicense === 'has-license' ? 'יש' : 'אין'
			}
		`;

		if (
			educationDetails.highSchool.years ||
			educationDetails.highSchool.mainSubject ||
			educationDetails.highSchool.nameAndCertificates
		) {
			message += `
			השכלה תיכונית / מקצועית:
			${
				educationDetails.highSchool.years
					? 'שנות לימוד: ' + educationDetails.highSchool.years
					: ''
			}
			${
				educationDetails.highSchool.mainSubject
					? 'המקצוע הנלמד: ' + educationDetails.highSchool.mainSubject
					: ''
			}
			${
				educationDetails.highSchool.nameAndCertificates
					? 'שם המוסד והתעודות שבידך: ' +
					  educationDetails.highSchool.nameAndCertificates
					: ''
			}
			`;
		}

		if (
			educationDetails.university.years ||
			educationDetails.university.mainSubject ||
			educationDetails.university.nameAndCertificates
		) {
			message += `
			השכלה גבוהה:
			${
				educationDetails.university.years
					? 'שנות לימוד: ' + educationDetails.university.years
					: ''
			}
			${
				educationDetails.university.mainSubject
					? 'המקצוע הנלמד: ' + educationDetails.university.mainSubject
					: ''
			}
			${
				educationDetails.university.nameAndCertificates
					? 'שם המוסד והתעודות שבידך: ' +
					  educationDetails.university.nameAndCertificates
					: ''
			}
			`;
		}

		if (
			coursesDetails[0].courseName ||
			coursesDetails[0].institutionAndCertificates ||
			coursesDetails[0].subject ||
			coursesDetails[0].years
		) {
			message += `
			קורסים והשתלמויות הקשורים למקצוע או לעיסוק:`;

			coursesDetails.map((course) => {
				message += `
			שם הקורס: ${course.courseName}
			שנות לימוד: ${course.years}
			המקצוע הנלמד: ${course.subject}
			פרט/י את שם המוסד והתעודות שבידך: ${course.institutionAndCertificates}
			`;
			});
		}

		if (appliedToTempo.applied === 'yes') {
			message += `
			ניסיון להתקבל לטמפו: כן
			לתפקיד: ${appliedToTempo.role}
			`;
		} else {
			message += `
			ניסיון להתקבל לטמפו: לא
			`;
		}

		if (hasContactWithTempoMember.hasContact === 'yes') {
			message += `
			קשר עם לקוח/ספק/עובד טמפו: כן
			סוג הקשר: ${hasContactWithTempoMember.kindOfContact}
			עם מי: ${hasContactWithTempoMember.withWho}
			`;
		} else {
			message += `
			קשר עם לקוח/ספק/עובד טמפו: לא
			`;
		}

		if (hasRelationWithTempoMember.hasRelation === 'yes') {
			message += `
			קרוב משפחה מועסק בטמפו: כן
			סוג הקשר: ${hasRelationWithTempoMember.kindOfRelation}
			תפקיד: ${hasRelationWithTempoMember.whichRole}
			`;
		} else {
			message += `
			קרוב משפחה מועסק בטמפו: לא
			`;
		}

		if (
			previousWorkPlaces[0].workPlaceName ||
			previousWorkPlaces[0].role ||
			previousWorkPlaces[0].from ||
			previousWorkPlaces[0].to ||
			previousWorkPlaces[0].salary ||
			previousWorkPlaces[0].reasonForLeaving
		) {
			message += `
			מקומות עבודה קודמים:`;

			previousWorkPlaces.map((place) => {
				message += `
			שם החברה: ${place.workPlaceName}
			תפקיד: ${place.role}
			התחלה: ${place.from}
			סיום: ${place.to}
			השכר ברוטו: ${place.salary}
			סיבת עזיבה: ${place.reasonForLeaving}
			`;
			});
		}

		if (
			recommendations[0].recommendName ||
			recommendations[0].phoneNumber ||
			recommendations[0].role ||
			recommendations[0].fromWhere
		) {
			message += `
			המלצות:`;

			recommendations.map((recommand) => {
				message += `
			שם: ${recommand.recommendName}
			טלפון: ${recommand.phoneNumber}
			מקצוע או תפקיד: ${recommand.role}
			מהיכן מכיר אותך: ${recommand.fromWhere}
			`;
			});
		}

		if (
			languages[0].language ||
			languages[0].speaking ||
			languages[0].reading ||
			languages[0].writing ||
			languages[0].typing
		) {
			message += `
			שפות:`;

			languages.map((language) => {
				message += `
			שפה: ${language.language}
			רמת דיבור: ${language.speaking}
			רמת קריאה: ${language.reading}
			רמת כתיבה: ${language.writing}
			רמת הקלדה: ${language.typing}
			`;
			});
		}

		if (
			computerApplications[0].softwareName ||
			computerApplications[0].controllingLevel
		) {
			message += `
			יישומי מחשב:`;

			computerApplications.map((application) => {
				message += `
			שם התוכנה: ${application.softwareName}
			מידת השליטה: ${application.controllingLevel}
			`;
			});
		}

		message += `
			${deficiencies ? 'ליקויים שיש בהם כדי להשפיע על עבודתך: ' + deficiencies : ''}
			
			${comments ? 'הערות: ' + comments : ''}

			תאריך חתימה: ${signatureDate}
			`;

		return message;
	};

	const sendPdfByMail = async (base64_pbf) => {
		// set the text that should be send as a mail
		const detailsText = validateDataAndCreateText();
		if (!detailsText) return;

		setShowLoader(true);

		const mailRes = await SendDataAndFile(
			detailsText,
			base64_pbf,
			recruitingEmail,
		);

		if (!mailRes.success) alert(mailRes.message);
		setShowLoader(false);
		setShowPopUp(true);
	};

	const checkValidUrl = () => {
		const params = new URLSearchParams(window.location.search);
		if (!params) return;
		const id = params.get('id');
		if (id) {
			const foundRecruiting = recruitings.find((rec) => rec.id === +id);
			if (foundRecruiting) {
				setRecruitingEmail(foundRecruiting.email);
				return;
			}
		}
	};

	useEffect(() => {
		checkValidUrl();
	}, []);

	return (
		<div className='App' ref={contentRef}>
			{recruitingEmail ? (
				<>
					<ThanksPopUp showPopUp={showPopUp} />
					{showLoader && <Loader />}

					<div className='form-header flex justify-between'>
						<img src={tempo0} alt='tempo circles' />
						<h1>שאלון למועמד/ת לעבודה</h1>
						<img src={tempoLogo} alt='tempo logo' />
					</div>
					<div className='form-body'>
						<div className='container'>
							<div className='company-use'>
								<p className='company-use-title'>
									לשימוש החברה, אין צורך למלא
								</p>
								<form className='flex wrap'>
									<label
										htmlFor='candidate'
										className='flex align-center'
									>
										מועמד/ת ליחידה ארגונית:
										<input
											type='text'
											id='candidate'
											name='candidate'
										/>
									</label>
									<label
										htmlFor='role'
										className='flex align-center'
									>
										לתפקיד:
										<input
											type='text'
											id='role'
											name='role'
										/>
									</label>
									<label
										htmlFor='arrival'
										className='flex align-center'
									>
										הגיע/ה באמצעות:
										<input
											type='text'
											id='arrival'
											name='arrival'
										/>
									</label>
								</form>
							</div>

							<div className='personal-details'>
								<h4 className='title'>1. פרטים אישיים</h4>
								<form>
									<label htmlFor='lastName'>
										שם משפחה
										<input
											type='text'
											id='lastName'
											name='lastName'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='firstName'>
										שם פרטי
										<input
											type='text'
											id='firstName'
											name='firstName'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='idNumber'>
										מס' זהות
										<input
											type='text'
											id='idNumber'
											name='idNumber'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<div className='gender-wrapper'>
										<p className='gender-title'>מין</p>
										<div className='gender-checkbox'>
											<label htmlFor='male'>
												<input
													type='radio'
													name='gender'
													id='male'
													onChange={(e) => {
														onInputChange(
															e.target.name,
															e.target.id,
														);
													}}
												/>
												זכר
											</label>
											<label htmlFor='female'>
												<input
													type='radio'
													name='gender'
													id='female'
													onChange={(e) => {
														onInputChange(
															e.target.name,
															e.target.id,
														);
													}}
												/>
												נקבה
											</label>
										</div>
									</div>
									<label htmlFor='birthDate'>
										תאריך לידה
										<input
											type='date'
											name='birthDate'
											id='birthDate'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='birthCountry'>
										ארץ לידה
										<input
											type='text'
											id='birthCountry'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='yearOfImmigration'>
										שנת עליה
										<input
											type='text'
											id='yearOfImmigration'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='address'>
										כתובת
										<input
											type='text'
											id='address'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='phoneNumber'>
										מס' נייד
										<input
											type='text'
											id='phoneNumber'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<label htmlFor='profession'>
										מקצוע/עיסוק
										<input
											type='text'
											id='profession'
											onBlur={(e) => {
												onInputChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</label>
									<div className='car-wrapper'>
										<p className='car-title'>בעל/ת רכב</p>
										<div className='car-checkbox'>
											<label htmlFor='has-car'>
												<input
													type='radio'
													name='hasCar'
													id='has-car'
													onChange={(e) => {
														onInputChange(
															e.target.name,
															e.target.id,
														);
													}}
												/>
												כן
											</label>
											<label htmlFor="doesn't-have-car">
												<input
													type='radio'
													name='hasCar'
													id="doesn't-have-car"
													onChange={(e) => {
														onInputChange(
															e.target.name,
															e.target.id,
														);
													}}
												/>
												לא
											</label>
										</div>
									</div>
									<div className='license-wrapper'>
										<p className='license-title'>
											ברשותי יש / אין רישיון נהיגה
										</p>
										<div className='license-checkbox'>
											<label htmlFor='has-license'>
												<input
													type='radio'
													name='haslicense'
													id='has-license'
													onChange={(e) => {
														onInputChange(
															e.target.name,
															e.target.id,
														);
													}}
												/>
												כן
											</label>
											<label htmlFor="doesn't-have-license">
												<input
													type='radio'
													name='haslicense'
													id="doesn't-have-license"
													onChange={(e) => {
														onInputChange(
															e.target.name,
															e.target.id,
														);
													}}
												/>
												לא
											</label>
										</div>
									</div>
								</form>
							</div>

							<div className='education'>
								<h4 className='title'>2. השכלה</h4>

								<div className='education-table flex'>
									<div className='column flex-column text-center'>
										<div></div>
										<div>תיכונית / מקצועית</div>
										<div>גבוהה</div>
									</div>
									<div className='column flex-column text-center'>
										<div>שנות לימוד</div>
										<div>
											<input
												type='text'
												name='highSchool'
												id='years'
												onBlur={(e) => {
													onEducationChange(
														e.target.name,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												name='university'
												id='years'
												onBlur={(e) => {
													onEducationChange(
														e.target.name,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>המקצוע הנלמד</div>
										<div>
											<input
												type='text'
												name='highSchool'
												id='mainSubject'
												onBlur={(e) => {
													onEducationChange(
														e.target.name,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												name='university'
												id='mainSubject'
												onBlur={(e) => {
													onEducationChange(
														e.target.name,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>
											פרט/י את שם המוסד והתעודות שבידך
										</div>
										<div>
											<input
												type='text'
												name='highSchool'
												id='nameAndCertificates'
												onBlur={(e) => {
													onEducationChange(
														e.target.name,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												name='university'
												id='nameAndCertificates'
												onBlur={(e) => {
													onEducationChange(
														e.target.name,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='courses'>
								<h4 className='title'>
									3. קורסים והשתלמויות הקשורים למקצוע או
									לעיסוק
								</h4>

								<div className='courses-table flex'>
									<div className='column flex-column text-center'>
										<div>שם הקורס</div>
										<div>
											<input
												type='text'
												id='courseName'
												data-index='1'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='courseName'
												data-index='2'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='courseName'
												data-index='3'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>שנות לימוד</div>
										<div>
											<input
												type='text'
												id='years'
												data-index='1'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='years'
												data-index='2'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='years'
												data-index='3'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>המקצוע הנלמד</div>
										<div>
											<input
												type='text'
												id='subject'
												data-index='1'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='subject'
												data-index='2'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='subject'
												data-index='3'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>
											פרט/י את שם המוסד והתעודות שבידך
										</div>
										<div>
											<input
												type='text'
												id='institutionAndCertificates'
												data-index='1'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='institutionAndCertificates'
												data-index='2'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												id='institutionAndCertificates'
												data-index='3'
												onBlur={(e) => {
													onCoursesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='questions flex-column'>
								<div className='question1 flex align-center'>
									<h4 className='title'>
										4. האם הגשת בעבר מועמדות לטמפו?
									</h4>
									<div className='checkboxes flex'>
										<label htmlFor='dont-applaied'>
											<input
												type='radio'
												name='applied'
												id='dont-applaied'
												onChange={(e) => {
													onAppliedChange(
														e.target.name,
														'no',
													);
												}}
											/>
											לא
										</label>
										<label htmlFor='applaied'>
											<input
												type='radio'
												name='applied'
												id='applaied'
												onChange={(e) => {
													onAppliedChange(
														e.target.name,
														'yes',
													);
												}}
											/>
											כן
										</label>
									</div>
									<div className='if-yes flex align-center'>
										<p>אם כן, לאיזה תפקיד?</p>
										<input
											type='text'
											id='role'
											onBlur={(e) => {
												onAppliedChange(
													e.target.id,
													e.target.value,
												);
											}}
										/>
									</div>
								</div>

								<div className='question2 flex align-center'>
									<h4 className='title'>
										5. האם יש לך קשר עם לקוח/ספק/עובד טמפו?
									</h4>
									<div className='checkboxes flex'>
										<label
											htmlFor='dont-have-contact'
											className='flex align-center'
										>
											<input
												type='radio'
												name='hasContact'
												id='dont-have-contact'
												onChange={(e) => {
													onHasContactChange(
														e.target.name,
														'no',
													);
												}}
											/>
											לא
										</label>
										<label
											htmlFor='have-contact'
											className='flex align-center'
										>
											<input
												type='radio'
												name='hasContact'
												id='have-contact'
												onChange={(e) => {
													onHasContactChange(
														e.target.name,
														'yes',
													);
												}}
											/>
											כן
										</label>
									</div>
									<div className='if-yes flex align-center'>
										<div className='relation flex align-center'>
											<p>מהו סוג הקשר?</p>
											<input
												type='text'
												id='kindOfContact'
												onBlur={(e) => {
													onHasContactChange(
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div className='with-who flex align-center'>
											<p>עם מי?</p>
											<input
												type='text'
												id='withWho'
												onBlur={(e) => {
													onHasContactChange(
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
								</div>

								<div className='question3 flex align-center'>
									<h4 className='title'>
										6. האם קרוב משפחה מועסק בטמפו?
									</h4>
									<div className='checkboxes flex'>
										<label
											htmlFor='dont-have-family-relation'
											className='flex align-center'
										>
											<input
												type='radio'
												name='hasRelation'
												id='dont-have-family-relation'
												onChange={(e) => {
													onHasRelationChange(
														e.target.name,
														'no',
													);
												}}
											/>
											לא
										</label>
										<label
											htmlFor='have-family-relation'
											className='flex align-center'
										>
											<input
												type='radio'
												name='hasRelation'
												id='have-family-relation'
												onChange={(e) => {
													onHasRelationChange(
														e.target.name,
														'yes',
													);
												}}
											/>
											כן
										</label>
									</div>
									<div className='if-yes flex align-center'>
										<div className='relation flex align-center'>
											<p>מהי הקרבה?</p>
											<input
												type='text'
												id='kindOfRelation'
												onBlur={(e) => {
													onHasRelationChange(
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div className='which-role flex align-center'>
											<p>באיזה תפקיד?</p>
											<input
												type='text'
												id='whichRole'
												onBlur={(e) => {
													onHasRelationChange(
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='prev-places'>
								<div className='title flex wrap'>
									<h4>7. מקומות עבודה קודמים</h4>
									<span>(התחל ממקום העבודה האחרון)</span>
								</div>

								<div className='prev-places-table flex'>
									<div className='column flex-column text-center'>
										<div>שם החברה</div>
										<div>
											<input
												type='text'
												data-index='1'
												id='workPlaceName'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='2'
												id='workPlaceName'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='3'
												id='workPlaceName'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>תפקיד</div>
										<div>
											<input
												type='text'
												data-index='1'
												id='role'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='2'
												id='role'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='3'
												id='role'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div className='flex-column'>
											עבדתי במקום
											<div className='flex justify-center'>
												<span>מ-</span>
												<span>עד</span>
											</div>
										</div>
										<div className='flex'>
											<input
												type='text'
												data-index='1'
												id='from'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
											<input
												type='text'
												data-index='1'
												id='to'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div className='flex'>
											<input
												type='text'
												data-index='2'
												id='from'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
											<input
												type='text'
												data-index='2'
												id='to'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div className='flex'>
											<input
												type='text'
												id='from'
												data-index='3'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
											<input
												type='text'
												data-index='3'
												id='to'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>השכר ברוטו </div>
										<div>
											<input
												type='text'
												data-index='1'
												id='salary'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='2'
												id='salary'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='3'
												id='salary'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>סיבת עזיבה</div>
										<div>
											<input
												type='text'
												data-index='1'
												id='reasonForLeaving'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='2'
												id='reasonForLeaving'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
										<div>
											<input
												type='text'
												data-index='3'
												id='reasonForLeaving'
												onBlur={(e) => {
													onPreviousWorkPlacesChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='recommendation'>
								<div className='title flex wrap'>
									<h4>8. המלצות</h4>
									<p>
										(רשום שני אנשים היכולים להמליץ עליך
										מקצועית - רצוי ממונה לשעבר)
									</p>
								</div>

								<div className='recommendation-table flex'>
									<div className='column flex-column text-center'>
										<div>שם</div>
										<div>
											<input
												type='text'
												id='recommendName'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='1'
											/>
										</div>
										<div>
											<input
												type='text'
												id='recommendName'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='2'
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>מס' טלפון</div>
										<div>
											<input
												type='text'
												id='phoneNumber'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='1'
											/>
										</div>
										<div>
											<input
												type='text'
												id='phoneNumber'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='2'
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>מקצוע או תפקיד</div>
										<div>
											<input
												type='text'
												id='role'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='1'
											/>
										</div>
										<div>
											<input
												type='text'
												id='role'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='2'
											/>
										</div>
									</div>
									<div className='column flex-column text-center'>
										<div>מהיכן מכיר אותך</div>
										<div>
											<input
												type='text'
												id='fromWhere'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='1'
											/>
										</div>
										<div>
											<input
												type='text'
												id='fromWhere'
												onBlur={(e) => {
													onRecommendationsChange(
														e.target,
														e.target.id,
														e.target.value,
													);
												}}
												data-index='2'
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='languages-and-applications flex align-center justify-between'>
								<div className='languages'>
									<div className='title flex wrap'>
										<h4>9. שפות</h4>
										<p>
											(ציין את מידת שליטתך בשפה: חלשה,
											בינונית, טובה, טובה מאוד)
										</p>
									</div>

									<div className='languages-table flex'>
										<div className='column flex-column text-center'>
											<div>שפה</div>
											<div>
												<input
													type='text'
													id='language'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='1'
												/>
											</div>
											<div>
												<input
													type='text'
													id='language'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='2'
												/>
											</div>
											<div>
												<input
													type='text'
													id='language'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='3'
												/>
											</div>
											<div>
												<input
													type='text'
													id='language'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='4'
												/>
											</div>
										</div>
										<div className='column flex-column text-center'>
											<div>דיבור</div>
											<div>
												<input
													type='text'
													id='speaking'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='1'
												/>
											</div>
											<div>
												<input
													type='text'
													id='speaking'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='2'
												/>
											</div>
											<div>
												<input
													type='text'
													id='speaking'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='3'
												/>
											</div>
											<div>
												<input
													type='text'
													id='speaking'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='4'
												/>
											</div>
										</div>
										<div className='column flex-column text-center'>
											<div>קריאה</div>
											<div>
												<input
													type='text'
													id='reading'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='1'
												/>
											</div>
											<div>
												<input
													type='text'
													id='reading'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='2'
												/>
											</div>
											<div>
												<input
													type='text'
													id='reading'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='3'
												/>
											</div>
											<div>
												<input
													type='text'
													id='reading'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='4'
												/>
											</div>
										</div>
										<div className='column flex-column text-center'>
											<div>כתיבה</div>
											<div>
												<input
													type='text'
													id='writing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='1'
												/>
											</div>
											<div>
												<input
													type='text'
													id='writing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='2'
												/>
											</div>
											<div>
												<input
													type='text'
													id='writing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='3'
												/>
											</div>
											<div>
												<input
													type='text'
													id='writing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='4'
												/>
											</div>
										</div>
										<div className='column flex-column text-center'>
											<div>הקלדה</div>
											<div>
												<input
													type='text'
													id='typing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='1'
												/>
											</div>
											<div>
												<input
													type='text'
													id='typing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='2'
												/>
											</div>
											<div>
												<input
													type='text'
													id='typing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='3'
												/>
											</div>
											<div>
												<input
													type='text'
													id='typing'
													onBlur={(e) => {
														onLanguagesChange(
															e.target,
															e.target.id,
															e.target.value,
														);
													}}
													data-index='4'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='applications'>
									<h4 className='title'>10. יישומי מחשב</h4>

									<table>
										<thead>
											<tr>
												<th>שם התוכנה</th>
												<th>מידת השליטה</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<input
														type='text'
														id='softwareName'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
												<td>
													<input
														type='text'
														id='controllingLevel'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														type='text'
														id='softwareName'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
												<td>
													<input
														type='text'
														id='controllingLevel'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														type='text'
														id='softwareName'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
												<td>
													<input
														type='text'
														id='controllingLevel'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														type='text'
														id='softwareName'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
												<td>
													<input
														type='text'
														id='controllingLevel'
														onBlur={(e) => {
															onComputerApplicationsChange(
																e.target,
																e.target.id,
																e.target.value,
															);
														}}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div className='deficiencies'>
								<h4 className='title'>
									11. אנא פרט ליקויים שיש בהם כדי להשפיע על
									עבודתך
								</h4>

								<textarea
									name='deficiencies'
									id='deficiencies'
									cols='30'
									rows='2'
									onBlur={(e) => {
										setDeficiencies(e.target.value);
									}}
									className={isGeneratePdf ? 'pdf' : ''}
								></textarea>
							</div>

							<div className='signature'>
								<h4 className='title'>
									12. הנני מצהיר/ה כי זה שמי, זו חתימתי ותוכן
									הצהרתי דלעיל אמת.
								</h4>

								<div className='signature-and-date flex justify-center align-end'>
									<label
										htmlFor='signingDate'
										className='flex-column align-center'
									>
										<input
											type='date'
											id='date'
											defaultValue={
												new Date()
													.toISOString()
													.split('T')[0]
											}
											// onBlur={(e) => {
											// 	onsignatureDateChange(
											// 		e.target.id,
											// 		e.target.value,
											// 	);
											// }}
										/>
										תאריך
									</label>
									<div className='signature-wrapper flex-column align-center'>
										<SignatureCanvas
											isFinishSignature={
												isFinishSignature
											}
											setIsFinishSignature={
												setIsFinishSignature
											}
										/>
										<button
											onClick={(e) => {
												e.target.previousElementSibling.classList.add(
													'show',
												);
												e.target.classList.add('hide');
												document.body.style.overflow =
													'hidden';
											}}
										>
											לחץ כאן לחתימה
										</button>
										<span>חתימת המועמד/ת</span>
									</div>
								</div>
							</div>

							<div className='comments'>
								<h4 className='title'>13. הערות:</h4>
								<textarea
									name=''
									id=''
									cols='30'
									rows='5'
									onBlur={(e) => {
										setComments(e.target.value);
									}}
									className={isGeneratePdf ? 'pdf' : ''}
								></textarea>
							</div>

							<div
								className={
									isGeneratePdf
										? 'send-button pdf'
										: 'send-button'
								}
							>
								<input
									type='submit'
									value='שלח'
									onClick={GeneratePdf}
								/>
							</div>
						</div>
					</div>
					<div className='form-footer'>
						<div className='container flex justify-center wrap'>
							<div className='image'>
								<img src={tempoFooter1} alt='footer' />
							</div>
							<div className='image'>
								<img src={tempoFooter2} alt='footer' />
							</div>
							<div className='image'>
								<img src={tempoFooter3} alt='footer' />
							</div>
							<div className='image'>
								<img src={tempoFooter4} alt='footer' />
							</div>
						</div>
					</div>
				</>
			) : (
				<div
					className='flex align-center justify-center'
					style={{ height: '100vh' }}
				>
					<h1>קישור לא תקין</h1>
				</div>
			)}
		</div>
	);
}

export default App;
